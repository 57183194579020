import React from 'react';
import Grid from '@material-ui/core/Grid';
import { CityLogo } from '../ui/icons';
import footerUrl from '../../Resources/images/footer.jpg';
import mvgLogo from '../../Resources/images/logo_mvg_transparent.png';
import facebookLogo from '../../Resources/images/icons/facebook-box.png';
import youtubeLogo from '../../Resources/images/icons/youtube.png';
import linkedinLogo from '../../Resources/images/icons/linkedin-box.png';
import './_Header&Footer.scss';

const Footer = () => (
  <footer
    className="footer-wrapper"
    style={{
      background: `url(${footerUrl}) no-repeat`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <Grid
      container
      justify="center"
      alignItems="center"
      className="info-wrapper"
    >
      <Grid item xs={12} className="text-wrapper">
        <span>Correo: </span>
        {'info@mvgconstructora.com'}
      </Grid>
      <Grid item xs={12} className="text-wrapper">
        <span>Teléfonos: </span>
        {'(02) 600 5848 / (02) 600 5847'}
      </Grid>
      <Grid item xs={12} className="text-wrapper">
        <span>Dirección: </span>
        {'Av. De Los Shyris BA-2 BQ-2 y Suecia. Edif. Renazzo Plaza. Ofi. 504'}
      </Grid>
      <Grid item xs={4} className="icons-wrapper">
        <a href="https://www.facebook.com/MVG-Constructora-Cia-Ltda-972015609508362/">
          <div
            style={{
              background: `url(${facebookLogo}) no-repeat`,
              backgroundPosition: 'center',
              float: 'right',
            }}
            className="icons"
          />
        </a>
      </Grid>
      <Grid item xs={4} className="icons-wrapper">
        <a href="https://www.youtube.com/channel/UCfoOUCqBPqdox4PTp-S4dcg">
          <div
            style={{
              background: `url(${youtubeLogo}) no-repeat`,
              backgroundPosition: 'center',
            }}
            className="icons"
          />
        </a>
      </Grid>
      <Grid item xs={4} className="icons-wrapper">
        {/* <a> */}
        <div
          style={{
            background: `url(${linkedinLogo}) no-repeat`,
            backgroundPosition: 'center',
            float: 'left',
          }}
          className="icons"
        />
        {/* </a> */}
      </Grid>
    </Grid>
    <div className="footer_img">
      <CityLogo
        url={mvgLogo}
        width="90px"
        height="90px"
        link
        linkTo="/"
        extraClass="img_footer"
      />
    </div>
    <div className="footer_text">
      MVG Constructora 2019. All rights reserved
    </div>
    <p className="webste-by">
      <span>
        {'Website by '}
      </span>
      <a href="https://www.linkedin.com/in/andre-torres-582949139/">
        Andre Torres
      </a>
    </p>
  </footer>
);

export default Footer;
