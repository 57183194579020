import sizes from './sizes';

const breakpoints = [
  // '40em',
  '48em',
  '75em',
];

// aliases for styled-components-breakpoint (in px)
breakpoints[sizes.mobile] = 0;
breakpoints[sizes.tablet] = 768;
breakpoints[sizes.desktop] = 1200;

export default {
  breakpoints,
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 64,
  ],
  colors: {
    navBarLinkHover: '#000',
    mainBackground: '#F27B4F',
    navBarBoxShadow: '#CCC',
    mainOrange: '#F25F52',
    dark: '#333333',
    grayBackground: '#F2F2F2',
    borderGray: '#C4C4C4',
    selectOptionHover: '#EEE',
    whiteText: '#F2F2F2',
  },
  space: [
    0, 4, 8, 16, 32, 64, 128, 256, 512,
  ],
};
