import React from 'react';
import Grid from '@material-ui/core/Grid';
import capriUrl from '../../../Resources/images/Capri.jpg';
import { CityLogo } from '../../ui/icons';
import mvgLogo from '../../../Resources/images/logo_mvg_transparent.png';
import './_LogoCapri.scss';

const LogoCapri = () => (
  <div align="center">
    <Grid container justify="center" alignItems="center" style={{ display: 'block' }}>
      <Grid item lg={12}>
        <div
          align="center"
          className="capri_wrapper"
          style={{
            background: `url(${capriUrl}) no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundAttachment: 'scroll',
          }}
        >
          <div className="mvg_logo">
            <CityLogo
              url={mvgLogo}
              width="150px"
              height="150px"
              link={false}
              linkTo="/"
              extraClass="img_footer"
            />
            <h1>MVG CONSTRUCTORA</h1>
          </div>
        </div>
      </Grid>
    </Grid>
  </div>
);

export default LogoCapri;
