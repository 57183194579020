import React from 'react';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import uioUrl from '../../../Resources/images/home/uioNoche.jpg';
import './_Mvg.scss';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: 'none',
        transition: theme.transitions.create('opacity'),
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    border: '4px solid currentColor',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px ${theme.spacing.unit + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
});

class MvgSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div align="center">
        <Grid container justify="center">
          <Grid item lg={12}>
            <div
              className="mvg_wrapper"
              style={{
                background: `url(${uioUrl}) no-repeat`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'scroll',
              }}
            >
              <div className="mvg-text">
                <h2>
                  MVG Constructora
                </h2>
                <p>
                  {'Trabajamos bajo la idea de que toda acción necesita de orden y equilibrio, con el fin de brindar a nuestros clientes un resultado de calidad en la solución de sus requerimientos'}
                </p>
                <Link to="/constructora">
                  <ButtonBase
                    focusRipple
                    className={classes.image}
                    onClick={this.onClick}
                    focusVisibleClassName={classes.focusVisible}
                    style={{
                      width: '250px',
                    }}
                  >
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      className={`${classes.imageTitle} button-anime`}
                    >
                      Quienes Somos
                    </Typography>
                  </ButtonBase>
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(MvgSection);
