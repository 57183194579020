import React from 'react';
import {Link} from 'react-router-dom';


export const CityLogo = (props) => {
  const classes = props.extraClass ? `img_cover ${props.extraClass}` : 'img_cover';
  const template = <div
    className={classes}
    style={{
      width: props.width || '',
      height: props.height || '',
      background: `url(${props.url}) no-repeat`
    }}
  ></div>

  if(props.link) {
    return(
      <Link to={props.linkTo} className="link_logo">
        {template}
      </Link>
    );
  }
  return template;
};