import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './theme';

const BaseTheme = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
);

export default BaseTheme;
