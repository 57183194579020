import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const Menu = () => (
  <div className="sidebar">
    <div className="menu">
      <Link className="link" to="/home">
        <Button className="navbar-button" style={{ color: '#8c888b' }}>Inicio</Button>
      </Link>
      <Link className="link" to="/constructora">
        <Button className="navbar-button" style={{ color: '#8c888b' }}>Quienes Somos</Button>
      </Link>
    </div>
  </div>
);

export default Menu;
