import React, { Component } from 'react';
import AdminLayout from '../../../Hoc/AdminLayout';

import FormField from '../../ui/formFields';
import { validate } from '../../ui/misc';

import Fileuploader from '../../ui/fileuploader';
import { firebaseSliders , firebaseDB, firebase } from '../../../firebase';

class AddEditSliders extends Component {

    state = {
        playerId:'',
        formType:'',
        formError: false,
        formSuccess:'',
        defaultImg:'',
        formdata:{
            title:{
                element:'input',
                value:'',
                config:{
                    label: 'Título de la Imagen',
                    name:'name_input',
                    type: 'text'
                },
                validation:{
                    required: true
                },
                valid: false,
                validationMessage:'',
                showlabel: true
            },
            description:{
                element:'input',
                value:'',
                config:{
                    label: 'Descripción de la Imagen',
                    name:'lastname_input',
                    type: 'text'
                },
                validation:{
                    required: true
                },
                valid: false,
                validationMessage:'',
                showlabel: true
            },
            image:{
                element:'image',
                value:'',
                validation:{
                    required: true
                },
                valid:false
            }
        }
    }

    updateFields = (player, playerId, formType , defaultImg) =>{
        const newFormdata = { ...this.state.formdata}

        for(let key in newFormdata){
            newFormdata[key].value = player[key];
            newFormdata[key].valid = true
        }

        this.setState({
            playerId,
            defaultImg,
            formType,
            formdata: newFormdata
        })
    }


    componentDidMount(){
    console.log(this.props);

        const sliderId = this.props.match.params.id;

        if(!sliderId){
            this.setState({
                formType:'Add Slider'
            })
        } else {
           firebaseDB.ref(`sliders/${sliderId}`).once('value')
           .then(snapshot => {
               const sliderData = snapshot.val();
                firebase.storage().ref('sliders')
                .child(sliderData.image).getDownloadURL()
                .then( url => {
                    this.updateFields(sliderData,sliderId,'Edit slider',url)
                }).catch( e => {
                    this.updateFields({
                        ...sliderData,
                        image:''
                    },sliderId,'Edit slider','')
                })
           })
        }

    }


    updateForm(element, content = ''){
        const newFormdata = {...this.state.formdata}
        const newElement = { ...newFormdata[element.id]}

        if(content === ''){
            newElement.value = element.event.target.value;
        } else {
            newElement.value = content
        }
        
        let validData = validate(newElement)
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1]

        newFormdata[element.id] = newElement;

        this.setState({
            formError: false,
            formdata: newFormdata
        })
    }


    successForm = (message) => {
        this.setState({
            formSuccess: message
        });
        setTimeout(()=>{
            this.setState({
                formSuccess:''
            });
        },2000)

    }

    submitForm(event){
        event.preventDefault();
        
        let dataToSubmit = {};
        let formIsValid = true;

        for(let key in this.state.formdata){
            dataToSubmit[key] = this.state.formdata[key].value;
            formIsValid = this.state.formdata[key].valid && formIsValid;
        }
        
        if(formIsValid){
            if(this.state.formType === 'Edit slider'){
                firebaseDB.ref(`sliders/${this.state.playerId}`)
                .update(dataToSubmit).then(()=>{
                    this.successForm('Update correctly');
                }).catch(e=>{
                    this.setState({formError: true})
                })
            } else {
              console.log(dataToSubmit);
              firebaseSliders.push(dataToSubmit).then(()=>{
                    this.props.history.push('/admin_sliders')
                }).catch(e => {
                  console.log(e);
                    this.setState({
                        formError: true
                    })
                })
            }
           
        } else {
            this.setState({
                formError: true
            })
        }
    }

    resetImage = () => {
        const newFormdata = {...this.state.formdata}
        newFormdata['image'].value = '';
        newFormdata['image'].valid = false;
        
        this.setState({
            defaultImg:'',
            formdata: newFormdata
        })
    }

    storeFilename = (filename) => {
        this.updateForm({id:'image'},filename)
    }

    render() {
        return (
            <AdminLayout>
                <div className="editplayers_dialog_wrapper">
                    <h2>
                        {this.state.formType}
                    </h2>
                    <div>
                        <form onSubmit={(event)=> this.submitForm(event)}>
            
                            <Fileuploader
                                dir="sliders"
                                tag={"Slider image"}
                                defaultImg={this.state.defaultImg}
                                defaultImgName={this.state.formdata.image.value}
                                resetImage={()=> this.resetImage()}
                                filename={(filename)=> this.storeFilename(filename)}
                            />


                            <FormField
                                id={'title'}
                                formdata={this.state.formdata.title}
                                change={(element)=> this.updateForm(element)}
                                
                            />

                            <FormField
                                id={'description'}
                                formdata={this.state.formdata.description}
                                change={(element)=> this.updateForm(element)}
                            />

                        <div className="success_label">{this.state.formSuccess}</div>
                            {this.state.formError ? 
                                <div className="error_label">
                                    Something is wrong
                                </div>
                                : ''
                            }
                            <div className="admin_submit">
                                <button onClick={(event)=> this.submitForm(event)}>
                                    {this.state.formType}
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </AdminLayout>
        );
    }
}

export default AddEditSliders;