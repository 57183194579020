import React, { Component } from 'react';
import './_LoadingPage.css';
import { CityLogo } from '../Components/ui/icons';

const LoadingPage = () => (
  <div align="center" className="loading-page">
    <CityLogo extraClass="loading-page" />
    <div className="bouncers">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
      <div className="bounce4" />
      <div className="bounce5" />
    </div>
  </div>
);

class WithSplashScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      timeoutId: 0,
    };
  }

  render() {
    const { loading, timeoutId } = this.state;
    const { wrappedComponent } = this.props;

    const WrappedComponent = wrappedComponent;
    if (loading) return LoadingPage();
    clearTimeout(timeoutId);
    return <WrappedComponent {...this.props} />;
  }
}

export default WithSplashScreen;
