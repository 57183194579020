import React from 'react';
import Grid from '@material-ui/core/Grid';
import Zoom from 'react-reveal/Zoom';
import misionUrl from '../../../Resources/images/about/mision.jpg';
import visionUrl from '../../../Resources/images/about/vision.jpg';
import './_MisionVision.scss';

const MisionVision = () => (
  <div align="center" className="mision_wrapper">
    <Grid container justify="center" alignItems="center">
      <Grid item xs={10} md={4}>
        <Zoom left>
          <img alt="mision" className="img-wrapper" src={misionUrl} />
        </Zoom>
      </Grid>
      <Grid item xs={10} md={8}>
        <Zoom left>
          <div className="text-wrapper">
            <h1>Misión</h1>
            <p>
              Apoyar al fortalecimiento de la infraestructura de
              nuestra Ciudad y del País, mediante la ejecución de
              proyectos de calidad y vanguardia; los cuales se adaptan
              a las necesidades de nuestros clientes. Contribuyendo así
              con el desarrollo del País.
            </p>
          </div>
        </Zoom>
      </Grid>
      <Grid item xs={10} md={8}>
        <Zoom right>
          <div className="text-wrapper">
            <h1 style={{ textAlign: 'left' }}>Visión</h1>
            <p style={{ textAlign: 'left' }}>Compromiso firme con las labores encomendadas, por medio de nuestra participación total y desinteresada, las cuales se ejecutan con los mayores estándares de calidad, seguridad, confianza. Siempre apegados al estricto cumplimiento de toso sus requerimientos.</p>
          </div>
        </Zoom>
      </Grid>
      <Grid item xs={10} md={4}>
        <Zoom right>
          <img alt="mision" className="img-wrapper bottom" width="400px" height="250px" src={visionUrl} />
        </Zoom>
      </Grid>
    </Grid>
  </div>
);

export default MisionVision;
