import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { firebase } from './firebase';
import { loadNews } from './redux/actions/newsActions';
import configureStore from './redux/configureStore';
import WithSplashScreen from './Hoc/withSplashScreen';
import BaseTheme from './baseTheme';
import './Resources/css/app.scss';


const store = configureStore();

// Dispatch actions to load initial state.
store.dispatch(loadNews());

console.log(store.getState().news.loading);

const appRouter = props => (
  <ReduxProvider store={store}>
    <BaseTheme>
      <BrowserRouter>
        <Routes {...props} />
      </BrowserRouter>
    </BaseTheme>
  </ReduxProvider>
);

// const App = <withSplashScreen WrappedComponent={appRouter} user={user} />;


firebase.auth().onAuthStateChanged((user) => {
  ReactDOM.render(<WithSplashScreen store={store} wrappedComponent={appRouter} user={user} />, document.getElementById('root'));
});
