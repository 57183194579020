import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import { CityLogo } from '../ui/icons';
import Menu from './menu';
import mvglogo from '../../Resources/images/logo_mvg_dark.png';
import './_Header&Footer.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpened: false,
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  handleDrawerToggle() {
    const { drawerOpened } = this.state;
    this.setState({
      drawerOpened: !drawerOpened,
    });
  }


  render() {
    const { drawerOpened } = this.state;
    return (
      <AppBar
        position="fixed"
        style={{
          backgroundColor: '#231F20',
          boxShadow: 'none',
          padding: '10px 10%',
          borderBottom: '2px solid #799900',
        }}
      >
        <Toolbar style={{ display: 'flex' }} className="navbar-header">
          <div style={{ flexGrow: 1 }}>
            <div className="header_logo">
              <CityLogo
                url={mvglogo}
                link
                linkTo="/"
                width="100px"
                height="100px"
              />
            </div>
          </div>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
              className="drawer_menu_icon"
            >
              <Icon>menu</Icon>
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <Menu />
          </Hidden>
          <div className="content-layout">
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor="left"
                open={drawerOpened}
                onClose={this.handleDrawerToggle}
                onClick={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true,
                }}
                className="sidebar-drawer"
              >
                <Menu />
              </Drawer>
            </Hidden>
          </div>
          {/* <Link to="/proyectos">
            <Button style={{ color: '#8c888b' }}>Proyectos</Button>
          </Link>
          <Link to="/ventas">
            <Button style={{ color: '#8c888b' }}>Ventas</Button>
          </Link>
          <Link to="/contactos">
            <Button style={{ color: '#8c888b' }}>Contactos</Button>
          </Link> */}
        </Toolbar>
      </AppBar>
    );
  }
}

export default Header;
