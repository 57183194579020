import React from 'react';
import { SectionLink } from 'react-scroll-section';
import styled from 'styled-components';

const Menu = styled.ul`
  position: fixed;
  z-index: 1;
  left: 90%;
  top: 22%;
  transform: translateX(-50%);
  display: grid;
  direction: rtl;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  margin-top: 126px;
`;

const Item = styled.li`
  width: 140px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: all 0.25s;
  margin: 10px 0;
  padding: 15px 10px;
  font-weight: bold;
  font-size: 18px;
  user-select: none;
  color: ${props => (props.selected ? '#799900' : 'white')};
  border-right: 5px solid ${props => (props.selected ? '#799900' : 'transparent')};
`;

const MenuItem = ({ section, children }) => (
  <SectionLink section={section}>
    {link => (
      <Item onClick={link.onClick} selected={link.isSelected}>
        {children}
      </Item>
    )}
  </SectionLink>
);

export { MenuItem as Item, Menu };

export default styled;
