import styled from 'styled-components';

const SectionContainer = styled.section`
  min-width: 320px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 4em;
  scroll-behavior: smooth;
  position: 'relative';
`;

export default SectionContainer;
