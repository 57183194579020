import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import refineriaUrl from '../../../Resources/images/home/retro.jpg';
import smartHome from '../../../Resources/images/icons/smarthome.png';
import retroImg from '../../../Resources/images/icons/retro.png';
import architecture from '../../../Resources/images/icons/architecture.png';
import estructural from '../../../Resources/images/icons/estructural.png';
import { CityLogo } from '../../ui/icons';
import './_Obras.scss';

const styles = theme => ({
  button: {
    margin: 20,
    color: '#fff',
    backgroundColor: '#000',
    border: '2px solid #799900',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: 'none',
        transition: theme.transitions.create('opacity'),
      },
    },
  },
  focusVisible: {},
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    border: '4px solid currentColor',
    color: '#fff',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px ${theme.spacing.unit + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
});

class ObrasSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div align="center">
        <Grid container justify="center">
          <Grid item lg={12}>
            <div
              className="obras_wrapper"
              style={{
                background: `url(${refineriaUrl}) no-repeat`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'scroll',
              }}
            >
              <div className="mvg-text">
                <Grid container justify="center" alignItems="center" spacing={16}>
                  <Grid item xs={6} md={3} lg={3}>
                    <div className="house-icon">
                      <CityLogo
                        url={retroImg}
                        // link
                        // linkTo="/"
                        // margin: 10px;
                        extraClass="img-icon"
                        width="40px"
                        height="40px"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <div className="house-icon">
                      <CityLogo
                        url={estructural}
                        // link
                        // linkTo="/"
                        // margin: 10px;
                        extraClass="img-icon"
                        width="40px"
                        height="40px"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <div className="house-icon">
                      <CityLogo
                        url={architecture}
                        // link
                        // linkTo="/"
                        // margin: 10px;
                        extraClass="img-icon"
                        width="40px"
                        height="40px"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <div className="house-icon">
                      <CityLogo
                        url={smartHome}
                        // link
                        // linkTo="/"
                        // margin: 10px;
                        extraClass="img-icon"
                        width="40px"
                        height="40px"
                      />
                    </div>
                  </Grid>
                  {/* <Grid item xs={12}>
                  </Grid> */}
                </Grid>
                <p className="obras-title">
                  {'Construir, mediante la buena gestión de infraestructuras y equipos, que contribuyan a mejorar la calidad de vida de las personas, haciendo frente a cada reto con soluciones seguras, fiables e innovadoras'}
                </p>
                <div align="center">
                  <Link to="/constructora">
                    <ButtonBase
                      focusRipple
                      className={classes.image}
                      onClick={this.onClick}
                      focusVisibleClassName={classes.focusVisible}
                      style={{
                        width: '250px',
                      }}
                    >
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                        className={`${classes.imageTitle} button-anime`}
                      >
                        Nuestros proyectos
                      </Typography>
                    </ButtonBase>
                  </Link>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ObrasSection);
