import React from 'react';
import Grid from '@material-ui/core/Grid';
// import BasilicaUrl from '../../../Resources/images/partners/Obra.jpeg';
import BasilicaUrl from '../../../Resources/images/ecuador.jpg';
// import BasilicaUrl from '../../../Resources/images/home/Basilica.png';
// import BasilicaUrl from '../../../Resources/images/partners/Basilica2.jpeg';
import petroUrl from '../../../Resources/images/partners/petro.png';
import epmmopUrl from '../../../Resources/images/partners/epmmop.png';
import gyeUrl from '../../../Resources/images/partners/gye.png';
import ziraUrl from '../../../Resources/images/partners/zira.png';
import alcaldiaUrl from '../../../Resources/images/partners/alcaldia.png';
import asertiaUrl from '../../../Resources/images/partners/asertia.png';
import epmapsUrl from '../../../Resources/images/partners/EPMAPS.png';
import './_Partners.scss';

const PartnersSection = () => (
  <div align="center">
    <Grid container justify="center">
      <Grid item lg={12}>
        <div
          className="partners_wrapper"
          style={{
            background: `url(${BasilicaUrl}) no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundAttachment: 'scroll',
            // opacity: '0.5',
          }}
        >
          <Grid container spacing={8} alignItems="center" className="logos-wrapper">
            <Grid item xs={12}>
              <h2>
                Nuestros Clientes
              </h2>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <img
                className="client-logo"
                src={asertiaUrl}
                width="180px"
                height="120px"
                alt="asertia"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <img
                className="client-logo"
                src={epmmopUrl}
                width="150px"
                height="90px"
                alt="epmmop"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <img
                className="client-logo"
                src={gyeUrl}
                width="150px"
                height="150px"
                alt="gye"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <img
                className="client-logo"
                src={ziraUrl}
                width="160px"
                height="100px"
                alt="zira"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <img
                className="client-logo"
                src={alcaldiaUrl}
                width="170px"
                height="110px"
                alt="alcaldia"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <img
                className="client-logo"
                src={epmapsUrl}
                width="180px"
                height="120px"
                alt="asertia"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <img
                className="client-logo"
                src={petroUrl}
                width="223px"
                height="79px"
                alt="petro"
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  </div>
);

export default PartnersSection;
