import React from 'react';
import Grid from '@material-ui/core/Grid';
import Zoom from 'react-reveal/Zoom';
import aboutURL from '../../../Resources/images/about/aboutUs.jpg';
import './_AboutUs.scss';

const LogoCapri = () => (
  <div
    align="center"
    className="about_wrapper"
    style={{
      background: `url(${aboutURL}) no-repeat`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'scroll',
    }}
  >
    <div className="black-wrapper" />
    <Grid container justify="center" alignItems="center">
      <Grid item xs={10} md={8}>
        <div className="text-wrapper">
          <Zoom>
            <h1>Quíenes Somos</h1>
            <p>
              Somos una empresa familiar 100% ecuatoriana, con reconocida experiencia
              en la ejecución de obras civiles, viales, hidráulicas, de saneamiento, y
              con proyección a incursionar en el ámbito de bienes raíces, remediación
              ambiental y servicios petroleros.
              Ofrecemos calidad, experiencia, cumplimiento,
              en cada uno de nuestros proyectos, lo cual nos ha hecho
              acreedores al renombre Nacional, gracias al constante e ininterrumpido
              esfuerzo; siento esto la fuente de motivación para mejorar nuestros servicios
              día a día.
            </p>
          </Zoom>
        </div>
      </Grid>
    </Grid>
  </div>
);

export default LogoCapri;
