import React from 'react';
import './_Video.scss';

const VideoSection = () => (
  <div className="video_wrapper">
    <video autoPlay loop playsinline muted className="first-video">
      <source src="https://firebasestorage.googleapis.com/v0/b/mvg-app-4265c.appspot.com/o/videos%2Fcapri.mp4?alt=media&token=92a763cb-9c91-48eb-a1eb-2ced15a59845" type="video/mp4" />
    </video>
  </div>
);

export default VideoSection;
