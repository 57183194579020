import React from 'react';
import LogoCapri from './LogoCapri';
import AboutUs from './AboutUs';
import MisionVision from './MisionVision';
import Footer from '../Header_footer/Footer';

const Constructora = () => (
  <div className="bck_white">
    <LogoCapri />
    <AboutUs />
    <MisionVision />
    <Footer />
  </div>
);

export default Constructora;
