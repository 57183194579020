import React from 'react';
import { Switch } from 'react-router-dom';
import Layout from './Hoc/Layout';
import PrivateRoute from './Components/authRoutes/privateRoutes';
import PublicRoute from './Components/authRoutes/publicRoutes';
import Home from './Components/home';
import SignIn from './Components/signin';
import Constructora from './Components/constructora';
import Dashboard from './Components/admin/Dashboard';
import AdminSliders from './Components/admin/sliders';
import AddEditSliders from './Components/admin/sliders/addEditSliders';
import ScrollToTop from './scrollToTop';

const Routes = props => (
  <Layout>
    <ScrollToTop>
      <Switch>
        <PublicRoute {...props} exact component={Constructora} path="/constructora" />
        <PublicRoute {...props} exact component={Home} path="/proyectos" />
        <PublicRoute {...props} exact component={Home} path="/ventas" />
        <PublicRoute {...props} exact component={Home} path="/contactos" />
        <PrivateRoute {...props} path="/admin_sliders/add_sliders" exact component={AddEditSliders} />
        <PrivateRoute {...props} path="/admin_sliders/add_sliders/:id" exact component={AddEditSliders} />
        <PrivateRoute {...props} path="/admin_sliders" exact component={AdminSliders} />
        <PrivateRoute {...props} path="/dashboard" exact component={Dashboard} />
        <PublicRoute {...props} restricted path="/sign_in" exact component={SignIn} />
        <PublicRoute {...props} restricted={false} path="/" exact withoutFooter component={Home} />
        <PublicRoute {...props} restricted={false} path="/home" exact withoutFooter component={Home} />
      </Switch>
    </ScrollToTop>
  </Layout>
);

export default Routes;
