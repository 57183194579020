import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'

const config = {
apiKey: "AIzaSyAtfSla7Czw6RtB2ie1IzCMhLfLpMTbZLc",
authDomain: "mvg-app-4265c.firebaseapp.com",
databaseURL: "https://mvg-app-4265c.firebaseio.com",
projectId: "mvg-app-4265c",
storageBucket: "mvg-app-4265c.appspot.com",
messagingSenderId: "895933151765"
};
firebase.initializeApp(config);

const firebaseDB = firebase.database();
const firebaseSliders = firebaseDB.ref('sliders');

export {
    firebase,
    firebaseSliders,
    firebaseDB
}