import React from 'react';
// import Featured from './featured';
import { ScrollingProvider, Section } from 'react-scroll-section';
import VideoSection from './video';
import MvgSection from './mvg';
import ObrasSection from './obras';
import PartnersSection from './partners';
import Footer from '../Header_footer/Footer';
import { Menu, Item } from './sideNav/menu';
import SectionContainer from './sideNav/sectionContainer';

const Home = () => (
  <div className="bck_blue">
    <ScrollingProvider scrollBehavior="smooth">
      <Menu>
        <Item section="resumen">Resumen</Item>
        <Item section="mvg">Quienes Somos</Item>
        <Item section="obras">Servicios</Item>
        <Item section="clientes">Clientes</Item>
        <Item section="contactos">Contactos</Item>
      </Menu>
      <Section id="resumen">
        <SectionContainer background="lightblue">
          <VideoSection />
        </SectionContainer>
      </Section>
      <Section id="mvg" style={{ marginTop: '-2%' }}>
        <SectionContainer background="lightblue">
          <MvgSection />
        </SectionContainer>
      </Section>
      <Section id="obras">
        <SectionContainer background="lightblue">
          <ObrasSection />
        </SectionContainer>
      </Section>
      <Section id="clientes">
        <SectionContainer background="lightblue">
          <PartnersSection />
        </SectionContainer>
      </Section>
      <Section id="contactos">
        <SectionContainer background="lightblue">
          <Footer />
        </SectionContainer>
      </Section>
    </ScrollingProvider>
  </div>
);

export default Home;
